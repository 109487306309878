/* global axios */
import ApiClient from './ApiClient';

class BroadcastsAPI extends ApiClient {
  constructor() {
    super('broadcasts', { accountScoped: true });
  }

  getMessages(broadcast_id, page, perPage) {
    let result = axios.get(`${this.url}/${broadcast_id}/messages`,{
      params: {
        page,
        per_page: perPage
      }
    });
    return result;
  }

  messagesStatus(broadcast_id) {
    console.log(`${this.url}/${broadcast_id}/messagesStatus`)
    let result = axios.get(`${this.url}/${broadcast_id}/messagesStatus`);
    console.log("result",result)
    return result;
  }

  getBroadcastReports(broadcast_id) {
    return axios.get(`${this.url}/${broadcast_id}/broadcastsReports`);
  }
}

export default new BroadcastsAPI();
